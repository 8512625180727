import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { getFieldFromType } from '../../../components/Forms/FieldTypes';
import { Controller, useForm } from 'react-hook-form';
import { InputError } from 'components';

type SpecificationChangeFlags = {
  shouldUpdateSubType?: boolean;
  shouldUpdateStandard?: boolean;
};

export const SpecificationSection = ({
  rows,
  addRow,
  deleteRow,
  saveSpecification,
  cancel,
  isRecipe,
  t,
  material,
}) => {
  const [editMode, setEditMode] = useState(false);
  const form = useForm({});

  const config = JSON.parse(localStorage.getItem('config') || '{}');

  const configTestTypes = config.testTypes;
  const configTestSubTypes = config.testSubTypes;
  const specificationConfigs = config.specificationConfig;
  const testTypeOptions = [
    ...configTestTypes.map((item) => ({
      label: t(`project:testType_${item.type}`),
      type: item.type,
      value: item._id,
    })),
  ];

  const testSubtypeOptions = [
    ...configTestSubTypes.map((item) => ({
      label: t(`project:testSubType_${item.type}`),
      type: item.type,
      testType: item.testType,
      value: item._id,
    })),
  ];

  const specConfigOptions = [
    ...specificationConfigs.map((item) => ({
      label: item.name,
      subType: item.subtype,
      value: item._id,
      description: item.description,
    })),
  ];

  const [rowState, setRowState] = useState(rows);

  Object.values(rowState).map((row: any, index) => {
    const minField = `${row.generatedId}.range.min`;
    const maxField = `${row.generatedId}.range.max`;

    form.register(minField, {
      validate: (value) => {
        const maxValue = form.getValues(maxField);
        if (
          (value === '' && maxValue !== '') ||
          (value !== '' && maxValue === '')
        ) {
          form.setError(maxField, {
            type: 'custom',
          });
          return false;
        } else {
          form.clearErrors(maxField);
          return true;
        }
      },
    });

    form.register(maxField, {
      validate: (value) => {
        const minValue = form.getValues(minField);
        if (
          (value === '' && minValue !== '') ||
          (value !== '' && minValue === '')
        ) {
          form.setError(minField, {
            type: 'custom',
            message: 'Both min and max are required',
          });
          return false;
        } else {
          form.clearErrors(minField);
          return true;
        }
      },
    });

    form.watch([minField, maxField], () => {
      form.trigger([minField, maxField]);
    });
  });

  useEffect(() => {
    setRowState(Object.values(rows));
  }, [rows]);

  return (
    <Grid className={'box-holder'} xs={isRecipe ? 12 : 5}>
      <form
        onSubmit={form.handleSubmit(async (data) => {
          const isFormValid = await form.trigger();
          if (isFormValid) {
            const success = await saveSpecification(
              Object.values(data)
                .filter((item) => !item.deleted && item.config)
                .map((item) => {
                  Object.keys(item).forEach((key) => {
                    if (item[key]?.min === '' || item[key]?.max === '') {
                      delete item[key];
                    }
                  });
                  delete item.generatedId;
                  return item;
                }),
            );
            if (success) {
              setEditMode(false);
              form.reset();
            }
          }
        })}
      >
        <Grid container justify={'space-between'} alignItems={'center'}>
          <Grid item xs={2}>
            <Typography variant={'h3'}>{t('specification')}</Typography>
          </Grid>
          <Grid item container xs={3}>
            {editMode && (
              <>
                <Grid item xs={6}>
                  <Button
                    color="default"
                    variant="contained"
                    onClick={() => {
                      form.reset();
                      cancel();
                      setEditMode(false);
                    }}
                  >
                    {t('common:cancel')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button color="default" variant="outlined" type={'submit'}>
                    {t('save')}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
          {!editMode && !material?.usedInProgressProject && (
            <Button
              color="default"
              variant="outlined"
              onClick={() => {
                setEditMode(true);
              }}
            >
              {t('edit')}
            </Button>
          )}
        </Grid>

        <Grid container>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('templates:testType')}*</TableCell>
                  <TableCell>{t('templates:testSubType')}*</TableCell>
                  <TableCell>{t('standard')}*</TableCell>
                  <TableCell>{t('conditions')}</TableCell>
                  <TableCell>{t('range')}</TableCell>
                  <TableCell>{t('unit')}</TableCell>
                  {config.specificationDescription ? (
                    <TableCell>
                      {t(
                        config.customDescriptionTitle
                          ? 'project:instructions'
                          : 'project:description',
                      )}
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(rowState).map((row: any, index) => (
                  <Row
                    row={row}
                    index={index}
                    form={form}
                    editMode={editMode}
                    t={t}
                    rows={rows}
                    deleteRow={deleteRow}
                    testTypeOptions={testTypeOptions}
                    testSubTypeOptions={testSubtypeOptions}
                    specificationConfigOptions={specConfigOptions}
                    showDescription={config.specificationDescription}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justify={'flex-end'} key={'edit-' + editMode}>
            {editMode && (
              <IconButton
                // key={index}
                aria-label="edit"
                color="secondary"
                size="small"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={() => addRow({ range: {}, generatedId: Date.now() })}
              >
                <AddIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

const Row = ({
  row,
  rows,
  index,
  form,
  editMode,
  t,
  deleteRow,
  testTypeOptions,
  testSubTypeOptions,
  specificationConfigOptions,
  showDescription = false,
}) => {
  const [testSubTypes, setTestSubtypes] = useState(testSubTypeOptions);

  const [specConfigs, setSpecConfigs] = useState(specificationConfigOptions);

  const [existingConfigs, setExistingConfigs] = useState([] as any);

  const [updateFlags, setUpdateFlags] = useState({
    shouldUpdateSubType: false,
    shouldUpdateStandard: false,
  } as SpecificationChangeFlags);

  const watcher = form.watch([
    `${row.generatedId}.testType`,
    `${row.generatedId}.testSubType`,
    `${row.generatedId}.config`,
  ]);

  useEffect(() => {
    const config = specificationConfigOptions.find(
      (config) => config.value === form.getValues(`${row.generatedId}.config`),
    );
    if (config) {
      row.description = config?.description || row?.description || '';
      form.setValue(
        `${row.generatedId}.description`,
        config?.description || row.description,
      );
    }
  }, [watcher, row, row.config]);

  useEffect(() => {
    const existingSubtypeConfigs: any = Object.values(form.getValues())
      .filter(
        (existingRow: any) =>
          existingRow.generatedId != row.generatedId &&
          existingRow.testSubType &&
          existingRow.config &&
          !existingRow.deleted,
      )
      .map((row: any) => ({
        testSubType: row.testSubType,
        config: row.config,
      }));

    setExistingConfigs([...existingSubtypeConfigs]);
  }, [rows]);

  const validateSubtypeConfig = (fieldName, value) => {
    const foundSubtypeConfig: any = existingConfigs.find(
      (item: any) =>
        item?.testSubType ===
          form.getValues(`${row.generatedId}.testSubType`) &&
        item?.config === value,
    );

    if (foundSubtypeConfig) {
      form.setError(`${row.generatedId}`, {
        type: 'custom',
        message: t('duplicateEntry'),
      });
    } else form.clearErrors(`${row.generatedId}`);
  };

  const updateTestSubtypes = (fieldName, value) => {
    const subtypes = testSubTypeOptions.filter(
      (item) => item.testType === value,
    );

    form.setValue(`${row.generatedId}.testSubType`, '');
    setUpdateFlags({ shouldUpdateSubType: true });
    setSpecConfigs([]);
    setTestSubtypes(subtypes);
  };

  const updateConfigOptions = (fieldName, value) => {
    const existing = existingConfigs
      .filter((item) => item.testSubType === value)
      .map((item) => item.config);

    const tempConfigs = specificationConfigOptions
      .filter((item) => item.subType._id === value)
      .map((item) => ({
        ...item,
        disabled: existing.includes(item.value),
      }));
    setUpdateFlags({ shouldUpdateStandard: true });
    setSpecConfigs(tempConfigs);
  };

  return !row.deleted ? (
    <TableRow key={'table-' + row.generatedId + index}>
      <TableCell key={row.generatedId + '-testType-'}>
        {getFieldFromType(
          form,
          {
            fieldName: `${row.generatedId}.testType`,
            label: 'testType',
            type: 'specification-select',
            options: testTypeOptions,
            required: true,
            span: 12,
            error: 'error',
            disabled: !editMode,
          } as any,
          row.generatedId + '-testType-',
          rows,
          t,
          editMode,
          !editMode,
          updateTestSubtypes,
        )}
      </TableCell>
      <TableCell
        key={row.generatedId + '-testSubType-'}
        style={{
          pointerEvents:
            updateFlags.shouldUpdateSubType || row.config ? 'initial' : 'none',
        }}
      >
        {getFieldFromType(
          form,
          {
            fieldName: `${row.generatedId}.testSubType`,
            type: 'specification-select',
            options: testSubTypes,
            disabled: !editMode,
            required: true,
            span: 12,
            maxLength: 255,
            error: 'error',
          } as any,
          row.generatedId + '-testSubType-',
          rows,
          t,
          editMode,
          !editMode,
          updateConfigOptions,
        )}
      </TableCell>
      <TableCell
        key={row.generatedId + '-config-'}
        style={{
          pointerEvents:
            updateFlags.shouldUpdateStandard || row.config ? 'initial' : 'none',
        }}
      >
        {getFieldFromType(
          form,
          {
            fieldName: `${row.generatedId}.config`,
            type: 'specification-select',
            options: specConfigs,
            disabled: !editMode,
            required: true,
            span: 12,
            maxLength: 255,
            error: 'error',
          } as any,
          row.generatedId + '-config-',
          rows,
          t,
          editMode,
          !editMode,
          validateSubtypeConfig,
        )}
      </TableCell>
      <TableCell key={row.generatedId + '-conditions-'}>
        {getFieldFromType(
          form,
          {
            fieldName: `${row.generatedId}.conditions`,
            label: '',
            type: 'table-text',
            required: false,
            span: 12,
          } as any,
          row.generatedId + '-conditions-',
          rows,
          t,
          editMode,
        )}
      </TableCell>
      <TableCell key={row.generatedId + '-range-'}>
        <Grid
          item
          container
          alignItems={'center'}
          wrap={'nowrap'}
          key={row.generatedId + '-range-'}
        >
          <Grid>
            <span
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              {getFieldFromType(
                form,
                {
                  fieldName: `${row.generatedId}.range.min`,
                  label: '',
                  subType: 'Float',
                  type: 'table-text',
                  required: false,
                  span: 12,
                } as any,
                row.generatedId + '-rangeMin-',
                rows,
                t,
                editMode,
              )}
            </span>
          </Grid>
          -
          <Grid>
            <span
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              {getFieldFromType(
                form,
                {
                  fieldName: `${row.generatedId}.range.max`,
                  label: '',
                  type: 'table-text',
                  subType: 'Float',
                  required: false,
                  span: 12,
                } as any,
                row.generatedId + '-rangeMax-',
                rows,
                t,
                editMode,
              )}
            </span>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell key={row.generatedId + '-unit-'}>
        {getFieldFromType(
          form,
          {
            fieldName: `${row.generatedId}.unit`,
            label: '',
            type: 'table-text',
            required: false,
            span: 12,
          } as any,
          row.generatedId + '-unit-',
          rows,
          t,
          editMode,
        )}
      </TableCell>
      <TableCell key={row.generatedId + '-description-'}>
        {getFieldFromType(
          form,
          {
            fieldName: `${row.generatedId}.description`,
            label: '',
            type: 'table-text',
            required: false,
            span: 12,
          } as any,
          row.generatedId + '-description-',
          rows,
          t,
          editMode,
        )}
      </TableCell>
      <TableCell>
        <Controller
          name={`${row.generatedId}.generatedId`}
          defaultValue={row.generatedId}
          render={() => (
            <TextField
              name={`${row.generatedId}.generatedId`}
              value={row.generatedId}
              type={'hidden'}
            />
          )}
          control={form.control}
        />
        <Controller
          name={`${row.generatedId}._id`}
          defaultValue={row._id}
          render={() => (
            <TextField
              name={`${row.generatedId}._id`}
              type={'hidden'}
              value={row._id}
            />
          )}
          control={form.control}
        />
      </TableCell>
      {form.formState.errors[row.generatedId] ? (
        <TableCell>
          <InputError />
        </TableCell>
      ) : null}
    </TableRow>
  ) : null;
};
