import Axios from 'axios';
import API from 'services/api';

export const getResultForProject = async (projectId, selectedResults) => {
  return await API.post(`/result/${projectId}`, {
    selectedResults,
  });
};

export const getSingleResultForProject = async ({ projectId, resultId }) => {
  return await API.get(`/result/${projectId}/${resultId}`);
};

export const getSingleResultTable = async ({ resultId, resultTableId }) => {
  return await API.get(`/result/${resultId}/result-table/${resultTableId}`);
};

export const getUploadLink = async (id, file) => {
  return await API.post(`/result/${id}/get-upload-file/`, { file });
};

export const getUploadFileUploader = async (id, file) => {
  return await API.post(`/project/${id}/get-upload-file/`, { file });
};

export const uploadFiles = async (url, formData) => {
  return await API.put(url, formData, {
    headers: {
      'Content-Type': `multipart/form-data`,
    },
  });
};

export const editManualInputs = async (id, data) => {
  return await API.put(`/result/${id}/inputs`, data, {});
};

export const deleteFile = async (id, file) => {
  return await API.delete(`/result/${id}/files/${file}`);
};

export const downloadReport = async (
  template,
  project,
  resultTableIds,
  tableQuery,
  chart,
  computedColumns,
  statisticalOperations,
) => {
  return await API.post(`result/${project}/report/${template}`, {
    ...tableQuery,
    chart,
    computedColumns,
    statisticalOperations,
    responseType: 'blob',
    resultTableIds,
  });
};

export const deleteFileUploader = async (projectId, file) => {
  return await API.delete(`/project/${projectId}/files/${file}`);
};

export const getExportFileUrl = async (projectId, data) => {
  return await API.post(`/result/${projectId}/export`, data);
};

export const checkIfRawDataFileExists = async (path) => {
  return await Axios.head(`${process.env.PUBLIC_URL}${path}`);
};

export const getSignedUrl = async (bucket, filePath) => {
  return await API.post(`/file-handler-s3/sign-url`, { bucket, filePath });
};

export const getExportedFile = async (fileUrl) => {
  const cleanedUrl = new URL(fileUrl);
  cleanedUrl.search = '';
  const fileName = decodeURIComponent(
    cleanedUrl.toString().split('/').reverse()[0],
  );

  const res = await Axios.get(fileUrl, {
    responseType: 'blob',
  });

  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  link.click();
};

export const excludeResult = async (
  resultID,
  resultTableID,
  reason,
  comment,
  commentId = '',
) => {
  return await API.put(
    `/result/${resultID}/result-table/${resultTableID}/exclude`,
    {
      reason,
      comment,
      commentId,
    },
  );
};

export const postComment = async (resultID, resultTableID, body) => {
  return await API.post(
    `/result/${resultID}/result-table/${resultTableID}/comment`,
    {
      ...body,
    },
  );
};

export const postDocumentComment = async (projectId, documentId, body) => {
  return await API.post(
    `/project/${projectId}/document/${documentId}/comment`,
    {
      ...body,
    },
  );
};

export const fetchChartData = async (data) => {
  return await API.post('result/search/complex/chart', data);
};

export const createSearchConfiguration = async (data: any) => {
  return await API.post('/search-configuration', data);
};

export const getSearchConfigurations = async (data: any) => {
  return await API.get(`/search-configuration`, {
    data,
  });
};
