import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { getFieldFromType } from '../../../components/Forms/FieldTypes';
import { useForm } from 'react-hook-form';

export const InformationSection = ({
  material,
  saveInformation,
  isRecipe,
  t,
  canEdit = true,
}) => {
  const [editMode, setEditMode] = useState(false);
  const form = useForm({});
  const [materialState, setMaterialState] = useState({ ...material });

  const config = JSON.parse(localStorage.getItem('config') || '{}');
  const formData = config?.forms?.materialForm;

  if (formData.tab1.fields?.baseFields) {
    materialState.type === 'Recipe'
      ? (formData.tab1.fields =
          config?.forms?.materialForm.tab1.fields.recipeFields)
      : (formData.tab1.fields =
          config?.forms?.materialForm.tab1.fields.baseFields);
  }

  const fields = formData?.tab1?.fields
    .filter((field) => field.showInMaterialForm)
    .map((field: any) => ({
      ...field,
      type: ['text', 'number'].includes(field.type)
        ? 'project-text'
        : ['search-select', 'select'].includes(field.type)
        ? 'project-search-select'
        : ['resource-datepicker'].includes(field.type)
        ? 'material-information-datepicker'
        : field.type,
    }));

  useEffect(() => {
    setMaterialState({ ...material });
  }, [material]);

  return (
    <Grid
      className={'box-holder'}
      xs={isRecipe ? 12 : 5}
      style={{ height: 'min-content' }}
    >
      <form
        onSubmit={form.handleSubmit(async (data) => {
          const isFormValid = await form.trigger();
          if (isFormValid) {
            const success = await saveInformation(data);
            if (success) {
              setEditMode(false);
            }
          }
        })}
      >
        <Grid container justify={'space-between'} alignItems={'center'}>
          <Grid item xs={6}>
            <Typography variant={'h3'}>{t('information')}</Typography>
          </Grid>
          <Grid item container xs={4}>
            {editMode && canEdit && (
              <>
                <Grid item xs={6}>
                  <Button
                    color="default"
                    variant="contained"
                    onClick={() => {
                      form.reset({
                        type: materialState?.type || '',
                      });
                      setEditMode(false);
                    }}
                  >
                    {t('common:cancel')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button color="default" variant="outlined" type={'submit'}>
                    {t('save')}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
          {!editMode && canEdit && !material?.usedInProgressProject && (
            <Button
              color="default"
              variant="outlined"
              onClick={() => {
                form.reset({
                  baseMaterial: materialState?.baseMaterial,
                  sampleShape: materialState?.sampleShape,
                  type: materialState?.type,
                });
                setEditMode(true);
              }}
            >
              {t('edit')}
            </Button>
          )}
        </Grid>
        <Grid container spacing={2}>
          {fields.map((field: any, index) => {
            field.label = t(field.label);
            field.error = t(field.error);
            return getFieldFromType(
              form,
              field,
              index,
              materialState,
              t,
              field.fieldName !== 'type' ? editMode : false,
            );
          })}
        </Grid>
      </form>
    </Grid>
  );
};
